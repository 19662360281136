import { Routes } from '@angular/router';
import { authGuard } from './shared/guard/auth.guard';
import { noAuthGuard } from './shared/guard/no-auth.guard';

export const routes: Routes = [
  { path: '', redirectTo: 'simulation-list', pathMatch: 'full' },
  {
    path: 'simulation-list',
    canMatch: [authGuard],
    loadComponent: () =>
      import('./deal-analytics/simulation-list/simulation-list.component').then(
        (c) => c.SimulationListComponent
      ),
  },
  {
    path: 'simulation-scenario-manage',
    canMatch: [authGuard],
    loadComponent: () =>
      import('./deal-analytics/deal-analytics.component').then(
        (c) => c.DealAnalyticsComponent
      ),
  },
  {
    path: 'simulation-scenario-manage/:id',
    canMatch: [authGuard],
    loadComponent: () =>
      import('./deal-analytics/deal-analytics.component').then(
        (c) => c.DealAnalyticsComponent
      ),
  },
  {
    path: 'login',
    canMatch: [noAuthGuard],
    loadComponent: () =>
      import('./sign-in/sign-in.component').then(
        (c) => c.SignInComponent
      ),
  }
];
