import { Injectable, signal } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { Apollo, gql } from 'apollo-angular';
import { GetUserDetails } from 'app/shared/Interface/generic.model';

@Injectable({
  providedIn: 'root'
})
export class GenericService {
  formDirtyFlag$ = new BehaviorSubject(false);
  simulationFormDirtyFlag$ = new BehaviorSubject(false);
  senarioFormDirtyFlag$ = new BehaviorSubject(false);
  public userDetail = signal<GetUserDetails>(this.userDetails);

  constructor(private apollo: Apollo) {}

  /*
 * function is used for save alert popup manage
 */
  formDirtyFlagIndetify(state: boolean) {
    this.formDirtyFlag$.next(state);
  }

  /*
 * function is used for simulation alert popup manage
 */
  simulationFormDirtyIdentify(state: boolean) {
    this.simulationFormDirtyFlag$.next(state);
  }

  /*
 * function is used for scenario alert popup manage
 */
  scenarioFormDirtyIndetify(state: boolean) {
    this.senarioFormDirtyFlag$.next(state);
  }

  get theme(): string {
    return document.documentElement.getAttribute('theme');
  }

  set theme(name: string) {
    document.documentElement.setAttribute('theme', name);
  }

  loginWithToken(token: string) {
    const LOGIN_WITH_TOKEN_MUTATION = gql`
      mutation loginWithToken($token: String!) {
        loginWithToken(token: $token) {
          token
          refreshToken
          user {
            id
            username
            firstName
            lastName
            email
          }
        }
      }
    `;
    return this.apollo.mutate({
      mutation: LOGIN_WITH_TOKEN_MUTATION,
      variables: {token},
      errorPolicy: 'all',
    });
  }

  private get userDetails(): GetUserDetails {
    return JSON.parse(localStorage.getItem('predealuser') || '{}');
  }
}
