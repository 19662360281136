import { Component, inject } from '@angular/core';
import { GenericService } from 'app/graphql/generic.service';

@Component({
  selector: 'app-footer',
  standalone: true,
  imports: [],
  templateUrl: './footer.component.html',
  styleUrl: './footer.component.scss'
})
export class FooterComponent {
  currentYear = new Date().getFullYear();
  genericService = inject(GenericService);

  get isDarkTheme(): boolean {
    return this.genericService.theme === 'dark' ?  true : false;
  }
}
