<div class="w-full footer">
  <div>
    <a href="https://www.fundae.ai" target="_blank" class="logo-link">
      @if (isDarkTheme) {
        <img src="https://fundae-website.s3.us-east-1.amazonaws.com/images/fundae+logo+for+darker+bg.png" alt="fundae" />
      } @else {
        <img src="https://fundae-website.s3.us-east-1.amazonaws.com/images/fundae+logo.png" alt="fundae" />
      }
    </a>
  </div>
  <div class="copyright-section border-t border-gray-400 py-5 mx-5">
    <div class="grid sm:grid-cols-3">
      <div class="flex gap-3 items-center">
        <a href="https://x.com/getGTNsmart?mx=2" target="_blank">
          <i class="fa-brands fa-square-x-twitter fa-lg"></i>
        </a>
        <a href="https://www.linkedin.com/company/fundae-inc/" target="_blank">
          <i class="fa-brands fa-linkedin fa-lg"></i>
        </a>
      </div>
      <h6 class="text-sm text-center">
        Copyright © {{ currentYear }} fundae Software Inc. All rights reserved.
      </h6>
      <h6 class="text-sm text-center sm:text-end">
        Terms of Use & Privacy Policy
      </h6>
    </div>
  </div>
</div>
